import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createI18n } from 'vue-i18n';

import { msalPlugin } from './plugins/msalPlugin';
import { msalInstance } from './store/auth/activedirectory';
import { AuthenticationResult, EventType } from '@azure/msal-browser';
import { CustomNavigationClient } from './router/NavigationClient';

import { messages } from './i18n';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Icon from './components/util/Icon.vue';
import LabeledInput from './components/util/LabeledInput.vue';
import LabeledSelect from './components/util/LabeledSelect.vue';
import Tooltip from './components/util/Tooltip.vue';
import Headline from './components/Headline.vue';
import SkillTable from './components/SkillTable.vue';
import SkillTableAccordion from './components/SkillTableAccordion.vue';
import Skill from './components/Skill.vue';
import SkillColor from './components/SkillColor.vue';
import SkillCircle from './components/SkillCircle.vue';
import Legend from './components/Legend.vue';
import Notification from './components/util/Notification.vue';
import AutoComplete from './components/util/AutoComplete.vue';
import DegreeBar from './components/DegreeBar.vue';
import CircleLegend from './components/CircleLegend.vue';

import Debug from './components/util/Debug.vue';

import { hasperms } from './plugins/smd-hasperms';

const i18n = createI18n({
  //legacy: false, // you must set `false`, to use Composition API
  locale: 'de',
  fallbacklocale: 'de',
  messages,
});

import { emitter } from './emitter';

const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

createApp(App)
  .component('Debug', Debug)
  .component('Notification', Notification)
  .component('AutoComplete', AutoComplete)
  .component('DegreeBar', DegreeBar)
  .component('Icon', Icon)
  .component('LabeledInput', LabeledInput)
  .component('LabeledSelect', LabeledSelect)
  .component('Legend', Legend)
  .component('Tooltip', Tooltip)
  .component('Headline', Headline)
  .component('SkillTable', SkillTable)
  .component('SkillColor', SkillColor)
  .component('SkillCircle', SkillCircle)
  .component('Skill', Skill)
  .component('SkillTableAccordion', SkillTableAccordion)
  .component('CircleLegend', CircleLegend)
  .provide('emitter', emitter)
  .use(store)
  .use(i18n)
  .use(router)
  .use(hasperms)
  .use(msalPlugin, msalInstance)
  .mount('#app');
