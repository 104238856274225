
import { defineComponent, ref } from 'vue';

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { mdiChevronRight } from '@mdi/js';

import { useIsAuthenticated } from '@/composition-api/useIsAuthenticated';
import LoginContainer from '@/components/LoginContainer.vue';

export default defineComponent({
  components: {
    LoginContainer,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    //const msalauth = useMsalAuthentication(InteractionType.Redirect, loginRequest);
    const auth = useIsAuthenticated();
    const datainstore = store.getters.isLoggedIn;

    if (auth.value && datainstore) {
      router.push({ name: 'Index' });
    }

    const login = () => {
      router.push({ name: 'Login' });
    };

    return {
      login,
      mdiChevronRight,
    };
  },
});
