import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09ba51de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "arrow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_LoginContainer = _resolveComponent("LoginContainer")!

  return (_openBlock(), _createBlock(_component_LoginContainer, null, {
    default: _withCtx(() => [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)))
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t('login.login')), 1),
        _createElementVNode("span", _hoisted_1, [
          _createVNode(_component_Icon, {
            path: _ctx.mdiChevronRight,
            width: 40,
            height: 40
          }, null, 8, ["path"])
        ])
      ])
    ]),
    _: 1
  }))
}