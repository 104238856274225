// import * as msal from '@azure/msal-browser';

import { Configuration, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { LogLevel } from '@azure/msal-browser';
import { z } from 'zod';
import store from '..';

interface AD_STATE {
  user: {
    firstname: string;
    id: number;
    lastname: string;
    unit_id: number;
    username: string;
  };
  roles: Array<string>;
  perms: Array<string>;
}

const SessionParser = z.object({
  user: z.object({
    id: z.number(),
    firstname: z.string(),
    lastname: z.string(),
    unit_id: z.number(),
    username: z.string(),
  }),
  roles: z.string().array(),
  perms: z.string().array(),
});

const msalConfig: Configuration = {
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID || '',
    authority: process.env.VUE_APP_MSAL_AUTHORITY || '',
    redirectUri: process.env.VUE_APP_MSAL_REDIRECT || '',
    postLogoutRedirectUri: process.env.VUE_APP_MSAL_REDIRECTLOGOUT || '',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
export const loginRequest = {
  scopes: ['User.Read'],
};

let session: AD_STATE = {
  user: {
    id: 0,
    firstname: '',
    lastname: '',
    unit_id: 0,
    username: '',
  },
  roles: [],
  perms: [],
};

console.log('init');

const parsed = SessionParser.safeParse(JSON.parse(localStorage.getItem('session') ?? '{}'));
if (parsed.success) {
  session = parsed.data;
}

const activedirectory = {
  state: session,
  getters: {
    validToken(state: AD_STATE) {
      return (token: 'refreshToken' | 'accessToken') => {
        return true; // NYI
      };
    },
    isLoggedIn(state: AD_STATE) {
      return state.user.id != 0;
    },
    accountInfo(state: AD_STATE) {
      return state;
    },
    getMyUnit(state: AD_STATE) {
      //return 2; // "1", "2"
      return state.user.unit_id;
    },
    hasRole(state: AD_STATE) {
      // return 'gf'; // "GF", "MANAGER", "WORKER"
      return (role: string) => state.roles.find((store: string) => store == role) || false;
    },
    hasPerm(state: AD_STATE) {
      return (perm: string) => {
        if (state.perms) {
          if (state.perms.includes('acl.bypass')) return true;
          return state.perms.includes(perm);
        }
        return false;
      };
    },
    loginMethod(state: any) {
      return 'activedirectory';
    },
  },
  mutations: {
    setState(state: AD_STATE, session: AD_STATE) {
      state.user = session.user;
      state.perms = session.perms;
      state.roles = session.roles;
    },
  },
  actions: {
    depersonify(context: any) {
      return context
        .dispatch('API', {
          url: 'auth/login',
          headers: { authorization: `Bearer ${localStorage.getItem('accessToken')}` },
          skipAuth: true,
        })
        .then((res: any) => {
          const parsed = SessionParser.safeParse({
            ...res.data.message,
          });
          if (parsed.success) {
            localStorage.setItem('originalsession', localStorage.getItem('session') ?? '{}');
            localStorage.removeItem('originalsession');
            localStorage.setItem('session', JSON.stringify(parsed.data));
            context.commit('setState', parsed.data);

            setTimeout(() => {
              window.location.reload();
            }, 500);
          } else {
            console.log(parsed.error);
          }
        });
    },
    impersonify(context: any, id: number) {
      return context
        .dispatch('API', {
          url: 'auth/login',
          headers: { authorization: `Bearer ${localStorage.getItem('accessToken')}`, impersonate: id },
          skipAuth: true,
        })
        .then((res: any) => {
          const parsed = SessionParser.safeParse({
            ...res.data.message,
          });
          if (parsed.success) {
            console.log('Saved', parsed.data);
            localStorage.setItem('originalsession', localStorage.getItem('session') ?? '{}');
            localStorage.setItem('session', JSON.stringify(parsed.data));
            context.commit('setState', parsed.data);

            setTimeout(() => {
              window.location.reload();
            }, 500);
          } else {
            console.log(parsed.error);
          }
        });
    },
    loadStore(context: any) {
      const parsed = SessionParser.safeParse(JSON.parse(localStorage.getItem('session') ?? '{}'));
      if (parsed.success) {
        context.commit('setState', parsed.data);
      }
      return;
    },
    inviteUser(context: any, userid: number) {
      return context
        .dispatch('API', { url: `admin/invite/${userid}`, method: 'post' })
        .then((res: any) => {
          console.log(res);
        })
        .catch((e: any) => {
          console.error(e);
        });
    },
    boardUser(context: any, data: { oid: string; key: string }) {
      return context
        .dispatch('API', { url: `admin/board/${data.key}/${data.oid}`, method: 'get' })
        .then((res: any) => {
          console.log(res);
        })
        .catch((e: any) => {
          console.error(e);
        });
    },
    login(context: any, payload: any) {
      localStorage.setItem('accessToken', payload.token);
      return context
        .dispatch('API', { url: 'auth/login', headers: { authorization: `Bearer ${payload.token}` }, skipAuth: true })
        .then((res: any) => {
          const parsed = SessionParser.safeParse({
            ...res.data.message,
          });
          if (parsed.success) {
            console.log('Saved', parsed.data);
            localStorage.setItem('session', JSON.stringify(parsed.data));
            context.commit('setState', parsed.data);
          } else {
            console.log(parsed.error);
          }
        });
    },
    logout(context: any) {
      localStorage.removeItem('session');
      return msalInstance.logoutRedirect().then((res) => {
        return res;
      });
    },
    changePassword(context: any) {
      return;
    },
  },
};

export default activedirectory;

// This Vuex module defines a state object with a user property, which will be used to store the authenticated user's details. It also defines a getters object with an authenticated getter, which returns true if the user is authenticated and false otherwise.

// The mutations object defines a setUser mutation, which sets the user property in the module's state.

// The actions object defines three actions: ad_login, ad_logout, and ad_handleRedirect. The ad_login action opens a popup window where the user can sign in with their Azure AD credentials, and then sets the authenticated user's details in the module's state. The ad_logout action logs the user out and clears the user property in the module's state. The ad_handleRedirect action handles the redirect response from Azure AD after the user has signed in, and sets the authenticated user's details in the module's state.
