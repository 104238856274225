import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const APIURL = process.env.VUE_APP_API;

interface APIRequest {
  method: 'get' | 'delete' | 'post' | 'put' | 'patch';
  url: string;
  data?: any;
  config?: any;
  headers?: any;
  skipAuth?: boolean;
  progresshandler?: any;
}

export type APIResult = AxiosResponse & { data: { status: number; message: any } };

const api = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    API(context: any, { method, url, data, config, headers, skipAuth, progresshandler }: APIRequest) {
      const options: AxiosRequestConfig = {
        method,
        url: APIURL + url,
        data,
        headers: headers || {},
        ...config,
      };

      if (progresshandler) {
        options.onUploadProgress = (progressEvent: ProgressEvent) => {
          progresshandler(progressEvent);
        };
      }

      if (!skipAuth) {
        if (!options.headers) options.headers = {};
        options.headers.authorization = `Bearer ${localStorage.getItem('accessToken')}`;
        const originalsession = localStorage.getItem('originalsession');
        const session = localStorage.getItem('session');
        if (originalsession !== null && session !== null) {
          options.headers.impersonate = JSON.parse(session).user.id;
        }
        return axios(options);

        // if (context.getters.validToken !== undefined) {
        //   if (context.getters.validToken('accessToken') && url != 'auth/refresh') {
        //     // ACCESS VALID
        //   } else if (context.getters.validToken('refreshToken') && url != 'auth/refresh') {
        //     // ACCESS INVALID .. REFRESH VALID
        //     return context.dispatch('refresh').then(() => {
        //       options.url = url;
        //       return context.dispatch('API', options);
        //     });
        //   } else {
        //     // NOTHING VALID .. Authless-request?
        //     context.dispatch('logout');
        //     return axios(options);
        //   }
        // } else {
        //   return axios(options);
        // }
      } else {
        return axios(options);
      }
    },
  },
};

export default api;
