import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a037f02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-element" }
const _hoisted_2 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label != '')
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: "role",
          class: _normalizeClass({ hasvalue: _ctx.model && _ctx.model != '' })
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      type: _ctx.type,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emit('focus')))
    }, null, 40, _hoisted_2), [
      [_vModelDynamic, _ctx.model]
    ])
  ]))
}